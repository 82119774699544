import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { VelocityComponent } from 'velocity-react'
import DropdownAnimation from '../DropdownAnimation/DropdownAnimation'
import BlogCategories from '../BlogCategories/BlogCategories'
import styles from './PostAllCategories.module.scss'

const tr = {
  categories: {
    es: 'Categorías del blog',
    ca: 'Categories',
    en: 'Categories',
    fr: 'Catégories'
  }
}

class PostAllCategories extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dropdownOpen: false
    }
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.showDropdown = this.showDropdown.bind(this)
    this.hideDropdown = this.hideDropdown.bind(this)
  }

  toggleDropdown () {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  showDropdown () {
    this.setState({ dropdownOpen: true })
  }

  hideDropdown () {
    this.setState({ dropdownOpen: false })
  }

  render () {
    const { edges, homePagePath, blogPagePath, locale, location } = this.props
    const { dropdownOpen } = this.state
    const el = (
      <BlogCategories
        edges={edges}
        homePagePath={homePagePath}
        blogPagePath={blogPagePath}
        locale={locale}
        location={location}
      />
    )
    return (
      <Fragment>
        <div className={styles.buttonContainer}>
          <button
            type='button'
            onClick={this.toggleDropdown}
            className={`${styles.button} ${dropdownOpen ? styles.active : ''}`}
          >
            <span>{tr.categories[locale]}</span>
          </button>
        </div>
        <div>
          <DropdownAnimation
            animation={dropdownOpen ? 'slideDown' : 'slideUp'}
            easing='easeInOutSine'
            duration={300}
            delay={dropdownOpen ? 0 : 300}
          >
            <div className={styles.dropdownContainer}>
              <VelocityComponent
                animation={{
                  opacity: dropdownOpen ? 1 : 0
                }}
                duration={400}
                delay={dropdownOpen ? 200 : 0}
              >
                <div className={styles.dropdownInner}>{el}</div>
              </VelocityComponent>
            </div>
          </DropdownAnimation>
        </div>
      </Fragment>
    )
  }
}

PostAllCategories.propTypes = {
  edges: PropTypes.array.isRequired,
  homePagePath: PropTypes.string.isRequired,
  blogPagePath: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
}

export default PostAllCategories
