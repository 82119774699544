import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'
import BookHeader from '../BookHeader/BookHeader'
import BookForm from '../BookForm/BookForm'
import styles from './PostSidebarBook.module.scss'

const tr = {
  contact: {
    es: 'Contacto',
    ca: 'Contacte',
    en: 'Contact',
    fr: 'Contact'
  }
}

class PostSidebarBook extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalVisible: false
    }
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
  }

  showModal () {
    this.setState({ modalVisible: true })
  }

  hideModal () {
    this.setState({ modalVisible: false })
  }

  render () {
    const { bookContent, locale } = this.props
    const { modalVisible } = this.state
    const bookFormHeader = {
      label: bookContent.room.dropdown.label,
      title: bookContent.room.dropdown.title
    }
    const bookFormLabels =
      bookContent.room.dropdown.content.book_form.book_form_labels
    const contactList = bookContent.spa.dropdown.content.book_contact.list
    return (
      <Fragment>
        <div className={styles.container}>
          <button
            type='button'
            onClick={this.showModal}
            className={styles.button}
          >
            <span>{bookFormHeader.label}</span>
          </button>
          <div className={styles.contactTitle}>{tr.contact[locale]}</div>
          <ul className={styles.contactList}>
            {contactList.map(({ link, text }, i) => {
              return (
                <li key={i}>
                  <a href={link}>{text}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <Modal isOpen={modalVisible} onRequestClose={this.hideModal} small>
          <Fragment>
            <BookHeader
              label={bookFormHeader.label}
              title={bookFormHeader.title}
              noTopPadding
            />
            <BookForm locale={locale} labels={bookFormLabels} pinned={false} />
          </Fragment>
        </Modal>
      </Fragment>
    )
  }
}

PostSidebarBook.propTypes = {
  bookContent: PropTypes.object,
  locale: PropTypes.string
}

export default PostSidebarBook
