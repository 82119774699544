import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import MainLayout from './MainLayout'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import getLanguageCode from '../utils/getLanguageCode'
import PostBody from '../components/PostBody/PostBody'
import SectionTitle from '../components/SectionTitle/SectionTitle'
import LinkedPosts from '../components/LinkedPosts/LinkedPosts'
import PostAllCategories from '../components/PostAllCategories/PostAllCategories'

const tr = {
  lastPosts: {
    es: 'Últimas entradas',
    ca: 'Últimes entrades',
    en: 'Last entries',
    fr: 'Dernières entrées'
  }
}

class PostTemplate extends Component {
  componentDidMount () {
    const locale = this.props.pageContext.locale
    const translations = this.props.pageContext.translations
    const langData = { locale, translations }
    this.props.setLangData(langData)
    this.props.showHeroBorder(true)
  }

  render () {
    const pageTitle = this.props.data.page.title
    const locale = this.props.pageContext.locale
    const bookContent = this.props.data.bookContent
    this.props.data.page.mainHeader = this.props.data.mainHeader
    const languageCode = getLanguageCode(locale)
    const { acf, content, date, categories } = this.props.data.page
    const { subtitle, lead, featuredMedia } = acf
    const metaTitle = pageTitle
    const metaDescription = lead
    const homePagePath = this.props.data.homePage.path
    const blogPagePath = this.props.data.blogPage.path
    const blogPageTitle = this.props.data.blogPage.title
    const { lastPosts, allCategories } = this.props.data
    const fullCurrPagePath = `${process.env.GATSBY_SITE_URL}${
      this.props.data.page.path
    }`
    const { location } = this.props

    return (
      <div>
        <Helmet htmlAttributes={{ lang: languageCode }}>
          <title>{metaTitle}</title>
          <meta name='description' content={metaDescription} />
          <link rel='canonical' href={fullCurrPagePath} />
          {this.props.pageContext.translations.map(
            ({ locale_name, path }, i) => {
              if (path === '/ca/' || path === '/en/' || path === '/fr/') {
                return null
              }

              return (
                <link
                  key={i}
                  rel='alternate'
                  hreflang={locale_name.replace('_', '-')}
                  href={`${process.env.GATSBY_SITE_URL}${path}`}
                />
              )
            }
          )}
          {this.props.pageContext.translations.map(
            ({ native_name, path }, i) => {
              if (native_name === 'English') {
                return (
                  <link
                    key={i}
                    rel='alternate'
                    hreflang='x-default'
                    href={`${process.env.GATSBY_SITE_URL}${path}`}
                  />
                )
              }
            }
          )}
        </Helmet>
        <MainLayout
          mainHeaderData={this.props.data.mainHeader}
          bookContent={bookContent}
          locale={locale}
          locationKey={this.props.location.key || ''}
          translations={this.props.pageContext.translations}
        >
          <CenterOverlay noTopPadding={false}>
            <PostBody
              homePagePath={homePagePath}
              blogPagePath={blogPagePath}
              blogPageTitle={blogPageTitle}
              featuredMedia={featuredMedia}
              title={pageTitle}
              subtitle={subtitle}
              date={date}
              categories={categories}
              lead={lead}
              content={content}
              bookContent={bookContent}
              locale={locale}
              fullCurrPagePath={fullCurrPagePath}
            />
            {allCategories && allCategories.edges.length > 0 && (
              <PostAllCategories
                edges={allCategories.edges}
                homePagePath={homePagePath}
                blogPagePath={this.props.data.page.path}
                locale={locale}
                location={location}
              />
            )}
            {lastPosts && (
              <Fragment>
                <SectionTitle text={tr.lastPosts[locale]} htmlElement='div' />
                <LinkedPosts edges={lastPosts.edges} fullWidth relatedPosts />
              </Fragment>
            )}
          </CenterOverlay>
        </MainLayout>
      </div>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ showHeroBorder, setLangData }) => {
  return { showHeroBorder, setLangData }
}

const mapDispatchToProps = dispatch => {
  return {
    showHeroBorder: show =>
      dispatch({
        type: `SHOW_DESKTOP_HEADER_BORDER`,
        showBorder: show
      }),
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostTemplate)

export const legalPageQuery = graphql`
  query($id: String!, $locale: String!) {
    page: wordpressPost(id: { eq: $id }) {
      ...WordpressPostContent
      # acf {
      #   metaTitle: meta_title
      #   metaDescription: meta_description
      # }
    }
    lastPosts: allWordpressPost(
      filter: { wpml_language_information: { language_code: { eq: $locale } } }
      limit: 4
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...WordpressPostContent
        }
      }
    }
    allCategories: allWordpressCategory(
      filter: {
        count: { gt: 0 }
        wpml_language_information: { language_code: { eq: $locale } }
      }
    ) {
      edges {
        node {
          id
          name
          count
          slug
          parentElement: parent_element {
            id
          }
        }
      }
    }
    bookContent: wordpressStickyBook(language_code: { eq: $locale }) {
      ...StickyBookContent
    }
    mainHeader: wordpressMainHeader(language_code: { eq: $locale }) {
      ...MainHeaderContent
    }
    homePage: wordpressPage(
      template: { eq: "page-templates/Home.php" }
      wpml_language_information: { language_code: { eq: $locale } }
    ) {
      path
    }
    blogPage: wordpressPage(
      template: { eq: "page-templates/Blog.php" }
      wpml_language_information: { language_code: { eq: $locale } }
    ) {
      path
      title
    }
  }
`
