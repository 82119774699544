import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import MobileShare from '../MobileShare/MobileShare'
import { sharePostButtons } from '../../constants/shareButtons'
import Icon from '../Icon/Icon'
import styles from './PostShare.module.scss'

const PostShare = ({ locale, path, title }) => {
  return (
    <Fragment>
      <div>
        <MediaQueryProvider width={1024} height={768}>
          <MediaQuery query='(min-width: 1100px)'>
            <div className={styles.wrap}>
              {sharePostButtons.map(({ icon, createHref }, i) => {
                return (
                  <a
                    key={i}
                    className={styles.button}
                    target='_blank'
                    rel='noreferrer noopener'
                    href={createHref(path, title)}
                  >
                    <span className={`${styles.icon} ${styles.default}`}>
                      <Icon name={icon.default} />
                    </span>
                    <span className={`${styles.icon} ${styles.inverted}`}>
                      <Icon name={icon.inverted} />
                    </span>
                  </a>
                )
              })}
            </div>
          </MediaQuery>
        </MediaQueryProvider>
      </div>
      <div>
        <MediaQueryProvider width={1024} height={768}>
          <MediaQuery query='(max-width: 1099px)'>
            <MobileShare path={path} title={title} locale={locale} />
          </MediaQuery>
        </MediaQueryProvider>
      </div>
    </Fragment>
  )
}

PostShare.propTypes = {
  locale: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default PostShare
