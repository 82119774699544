import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './PostBreadcrumbs.module.scss'
import { Link } from 'gatsby'

/**
 * @param {Array} categories
 * @param {String} id
 */
function getParentSlug (categories, parentId) {
  return categories.find(({ id }) => id === parentId).slug
}

const PostBreadcrumbs = ({
  homePagePath,
  blogPagePath,
  blogPageTitle,
  categories,
  title
}) => {
  return (
    <div className={styles.container}>
      <ol
        className={styles.list}
        vocab='https://schema.org/'
        typeof='BreadcrumbList'
      >
        <li property='itemListElement' typeof='ListItem'>
          <Link to={homePagePath} property='item' typeof='WebPage'>
            <span property='name'>Grau Roig</span>
          </Link>
          <meta property='position' content='1' />
        </li>
        <li property='itemListElement' typeof='ListItem'>
          <Link to={blogPagePath} property='item' typeof='WebPage'>
            <span property='name'>{blogPageTitle}</span>
          </Link>
          <meta property='position' content='2' />
        </li>
        {categories && (
          <Fragment>
            {categories.map(({ id, name, slug, parentElement }, i) => {
              const dest = parentElement
                ? `${homePagePath}${getParentSlug(
                  categories,
                  parentElement.id
                )}/${slug}`
                : `${homePagePath}${slug}`
              return (
                <li key={id} property='itemListElement' typeof='ListItem'>
                  <Link to={dest} property='item' typeof='WebPage'>
                    <span property='name'>{name}</span>
                  </Link>
                  <meta property='position' content={i + 3} />
                </li>
              )
            })}
          </Fragment>
        )}
        <li>
          <span>{title}</span>
        </li>
      </ol>
    </div>
  )
}

PostBreadcrumbs.propTypes = {
  homePagePath: PropTypes.string,
  blogPagePath: PropTypes.string,
  blogPageTitle: PropTypes.string,
  categories: PropTypes.array,
  title: PropTypes.string
}

export default PostBreadcrumbs
