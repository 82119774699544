import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import styles from './MobileShare.module.scss'
import { shareMobileButtons } from '../../constants/shareButtons'

class MobileShare extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showButtons: false
    }
    this.toggleButtons = this.toggleButtons.bind(this)
  }

  toggleButtons () {
    this.setState({ showButtons: !this.state.showButtons })
  }

  render () {
    const { path, title } = this.props
    const { showButtons } = this.state

    if (typeof document === 'undefined') return null

    return ReactDOM.createPortal(
      <div className={styles.wrap}>
        <div
          className={`${styles.buttonsContainer} ${
            showButtons ? styles.visible : ''
          }`}
        >
          {shareMobileButtons.map(({ icon, createHref }, i) => {
            return (
              <a
                key={i}
                className={styles.button}
                target='_blank'
                rel='noreferrer noopener'
                href={createHref(path, title)}
              >
                <span className={`${styles.icon} ${styles.default}`}>
                  <Icon name={icon.default} />
                </span>
              </a>
            )
          })}
        </div>
        <button
          type='button'
          onClick={this.toggleButtons}
          className={`${styles.toggle} ${showButtons ? styles.active : ''}`}
        >
          {showButtons ? (
            <Icon name='shareMobileClose' />
          ) : (
            <Icon name='shareMobile' />
          )}
        </button>
      </div>,
      document.querySelector('#overlays')
    )
  }
}

MobileShare.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

export default MobileShare
