import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styles from './PostHeader.module.scss'

const PostHeader = ({ featuredMedia, subtitle, title, date, categories }) => {
  let sizes = null
  let altText = ''
  let localFile = null
  let sourceUrl = null

  if (featuredMedia) {
    altText = featuredMedia.altText
    localFile = featuredMedia.localFile
    sourceUrl = featuredMedia.sourceUrl
    sizes = localFile ? localFile.childImageSharp.croppedSizes : null
  }

  return (
    <div className={styles.container}>
      {sizes && (
        <div className={styles.imageWrap}>
          <div className={styles.image}>
            <Img fluid={sizes} alt={altText} />
          </div>
        </div>
      )}
      {!sizes && sourceUrl && (
        <div className={styles.image}>
          <img src={sourceUrl} alt={altText} />
        </div>
      )}
      {categories && (
        <ul className={styles.categories}>
          {categories.map(({ id, name }) => {
            return <li key={id}>{name}</li>
          })}
        </ul>
      )}
      <h1
        className={`${styles.title} ${title.length <= 45 ? styles.large : ''}`}
      >
        {title}
      </h1>
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {date && <div className={styles.date}>{date}</div>}
    </div>
  )
}

PostHeader.propTypes = {
  featuredMedia: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array
}

export default PostHeader
