import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import Wysiwyg from '../Wysiwyg/Wysiwyg'
import styles from './PostBody.module.scss'
import PostHeader from '../PostHeader/PostHeader'
import PostSidebarBook from '../PostSidebarBook/PostSidebarBook'
import Lead from '../Lead/Lead'
import PostBreadcrumbs from '../PostBreadcrumbs/PostBreadcrumbs'
import PostShare from '../PostShare/PostShare'

const PostBody = ({
  homePagePath,
  blogPagePath,
  blogPageTitle,
  featuredMedia,
  content,
  title,
  subtitle,
  date,
  categories,
  lead,
  bookContent,
  locale,
  fullCurrPagePath
}) => {
  return (
    <Fragment>
      <PostBreadcrumbs
        homePagePath={homePagePath}
        blogPagePath={blogPagePath}
        blogPageTitle={blogPageTitle}
        categories={categories}
        title={title}
      />
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.spacer} />
          <PostHeader
            featuredMedia={featuredMedia}
            title={title}
            subtitle={subtitle}
            date={date}
            categories={categories}
          />
          <div className={styles.main}>
            <div className={`${styles.sidebar} ${styles.left}`}>
              <div className={styles.sticky}>
                <MediaQueryProvider width={1024} height={768}>
                  <MediaQuery query='(min-width: 1100px)'>
                    <PostSidebarBook
                      bookContent={bookContent}
                      locale={locale}
                    />
                  </MediaQuery>
                </MediaQueryProvider>
              </div>
            </div>
            {/* <div className={styles.separator} /> */}
            {lead && <Lead text={lead} centerOverlay lightBg sph />}
            <Wysiwyg content={content} centerOverlay lightBg />
            <div className={`${styles.sidebar} ${styles.right}`}>
              <div className={styles.sticky}>
                <PostShare
                  locale={locale}
                  path={fullCurrPagePath}
                  title={title}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overlay} />
      </div>
    </Fragment>
  )
}

PostBody.propTypes = {
  homePagePath: PropTypes.string,
  blogPagePath: PropTypes.string,
  blogPageTitle: PropTypes.string,
  featuredMedia: PropTypes.object,
  content: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array,
  bookContent: PropTypes.object,
  locale: PropTypes.string,
  fullCurrPagePath: PropTypes.string
}

export default PostBody
