/**
 * Based on:
 * https://github.com/SaraVieira/react-social-sharing/blob/master/src/consts.js
 */
export const sharePostButtons = [
  {
    type: 'facebook',
    network: 'Facebook',
    icon: {
      default: 'sharePostFacebook',
      inverted: 'shareGoldFacebook'
    },
    createHref: function (path) {
      return `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${path}`
      )}`
    }
  },
  {
    type: 'twitter',
    network: 'Twitter',
    icon: {
      default: 'sharePostTwitter',
      inverted: 'shareGoldTwitter'
    },
    createHref: function (path, title) {
      return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        `Cercle d’Economia | ${title}`
      )}&url=${encodeURIComponent(`${path}`)}`
    }
  },
  {
    type: 'mail',
    network: 'Email',
    icon: {
      default: 'sharePostMail',
      inverted: 'shareGoldMail'
    },
    createHref: function (path, title) {
      return `mailto:?subject=${encodeURIComponent(
        `Cercle d’Economia | ${title}`
      )}&body=${encodeURIComponent(`Cercle d’Economia | ${title}\n\n${path}`)}`
    }
  }
]

export const shareMobileButtons = [
  {
    type: 'facebook',
    network: 'Facebook',
    icon: {
      default: 'shareMobileFacebook'
    },
    createHref: function (path) {
      return `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${path}`
      )}`
    }
  },
  {
    type: 'twitter',
    network: 'Twitter',
    icon: {
      default: 'shareMobileTwitter'
    },
    createHref: function (path, title) {
      return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        `Cercle d’Economia | ${title}`
      )}&url=${encodeURIComponent(`${path}`)}`
    }
  },
  {
    type: 'mail',
    network: 'Email',
    icon: {
      default: 'shareMobileMail'
    },
    createHref: function (path, title) {
      return `mailto:?subject=${encodeURIComponent(
        `Cercle d’Economia | ${title}`
      )}&body=${encodeURIComponent(`Cercle d’Economia | ${title}\n\n${path}`)}`
    }
  }
]
